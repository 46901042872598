<template>
  <div>
    <VForm v-model="valid" lazy-validation ref="form" @submit.prevent="onSave">
      <VRow>
        <VCol cols="12" md="2">
          <div :class="$style.title">Дата отчёта</div>
        </VCol>
        <VCol cols="12" md="10">
          <VRow>
            <VCol cols="12" md="4">
              <template v-if="readonly">
                <StaticField :value="fields.name" />
              </template>
              <template v-else>
                <DateInput v-model="fields.name" :rules="rules.required" outlined dense auto-grow hideDetails="auto" />
              </template>
            </VCol>
            <VCol cols="12" md="8">
              <div class="pt-2">
                <VRow>
                  <VCol cols="12" md="6" lg="auto">
                    <VCheckbox v-model="fields.hasTimeDeviation" :disabled="readonly" label="Обнаружены отклонения по срокам" class="pt-0 mt-0" hideDetails />
                  </VCol>
                  <VCol cols="12" md="6" lg="auto">
                    <VCheckbox v-model="fields.hasQualityDeviation" :disabled="readonly" label="Обнаружены отклонения по качеству" class="pt-0 mt-0" hideDetails />
                  </VCol>
                </VRow>
              </div>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12" md="2">
          <div :class="$style.title">Комментарий</div>
        </VCol>
        <VCol cols="12" md="10">
          <template v-if="readonly">
            <StaticField :value="fields.comment" />
          </template>
          <template v-else>
            <VTextarea v-model="fields.comment" :rules="rules.required" outlined dense auto-grow rows="2" hideDetails="auto" />
          </template>
          <div class="mt-4">
            <FileFieldMultiple
                :readonly="readonly"
                :value="fields.files.map(({ id }) => ({ value: id }))"
                @input="(values) => fields.files = values.map(({ value }) => ({ id: value }))"
            />
          </div>
          <div v-if="!readonly" class="mt-4">
            <VBtn color="primary" class="mr-2" depressed :disabled="pending || !valid || !diff" @click="onSave">Сохранить</VBtn>
            <VBtn color="secondary" class="mr-2" depressed :disabled="pending" @click="onReset">Отмена</VBtn>
          </div>
        </VCol>
      </VRow>
    </VForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isArray, isEmpty } from 'lodash-es';
import StaticField from '@/components/general/StaticField/StaticField';
import DateInput from '@/components/general/DateInput/DateInput';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
export default {
  name: 'AppReportDetail',
  components: {
    StaticField,
    DateInput,
    FileFieldMultiple,
  },
  props: {
    readonly: { type: Boolean },
    values: {
      type: Object, default: () => ({
        name: '',
        comment: '',
        hasTimeDeviation: false,
        hasQualityDeviation: false,
        files: [],
      })
    },
  },
  data() {
    return {
      valid: true,
      fields: JSON.parse(JSON.stringify(this.values)),
      rules: {
        required: [
          v => {
            if (isArray(v) && isEmpty(v)) return 'Обязательное поле';
            if (!v) return 'Обязательное поле';
            return true;
          }
        ]
      },
    };
  },
  computed: {
    ...mapGetters({
      pending: 'inspect/pending',
    }),
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
  },
  methods: {
    onReset: function() {
      this.$emit('reset');
    },
    onSave: function() {
      if (this.$refs.form.validate()) this.$emit('submit', JSON.parse(JSON.stringify(this.fields)))
    }
  },
  watch: {
    values: {
      handler: function(values) {
        this.fields = JSON.parse(JSON.stringify(values));
      }
    },
  }
}
</script>

<style module lang="scss">
.title {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}
</style>
