<template>
  <div :class="$style.root">
    <div :class="$style.badges">
      <div class="float-left"><b>{{author.name}}</b> {{date}}</div>
      <VChip v-if="draft" class="mr-1" outlined small>Не просмотрено</VChip>
      <VChip v-if="actual" class="mr-1" outlined small>Актуальный</VChip>
    </div>
    <div :class="$style.body">
      <div v-if="edit">
        <VTextarea v-model="field"  rows="3" outlined auto-grow hide-details />
      </div>
      <div v-else>{{ message }}</div>
      <div :class="$style.controls">
        <template v-if="!answer">
          <template v-if="!edit">
            <VBtn v-if="!parent && author.id !== user.id" class="mr-1" small color="light" elevation="0" @click="onAnswer">Ответить</VBtn>
            <VBtn v-if="draft && (author.id === user.id)" class="mr-1" outlined small color="primary" @click="onEdit">Изменить</VBtn>
          </template>
          <template v-else >
            <VBtn class="mr-1" :disabled="!field" outlined small color="primary" @click="onSave">Сохранить</VBtn>
            <VBtn class="mr-1" outlined small color="secondary" @click="onReset">Отмена</VBtn>
          </template>
        </template>
      </div>
      <CommentForm v-if="answer" @submit="onSubmit" @reset="answer = false" />
    </div>
    <div v-if="items && items.length" :class="$style.answer">
      <Comment v-for="comment in items" :class="$style.item" v-bind="comment" :key="comment.id" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash-es';
import { USER_TYPES } from '@/store/inspect/enums';
import CommentForm from '@/components/general/CommentForm/CommentForm';

export default {
  name: 'Comment',
  components: {
    CommentForm,
  },
  props: {
    id: { type: String },
    application: { type: String },
    type: { type: String },
    code: { type: String },
    date: { type: String },
    draft: { type: Boolean },
    actual: { type: Boolean },
    author: { type: Object },
    message: { type: String },
    parent: { type: String },
    items: { type: Array },
  },
  data() {
    return {
      edit: false,
      answer: false,
      field: '',
    };
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      user: 'user/current',
    }),
    isInitiator() {
      return this.hasRole(get(USER_TYPES, 'INITIATOR.value'), 'inspect');
    },
    isReviewer() {
      return this.hasRole(get(USER_TYPES, 'REVIEWER.value'), 'inspect');
    },
  },
  methods: {
    ...mapActions({
      editComment: 'inspect/editChatComment',
      createComment: 'inspect/createChatComment',
      fetchCommentList: 'inspect/fetchChatList',
    }),
    onSubmit(message) {
      const parent = this.id;
      const type = this.type;
      const code = this.code;
      const application = this.application;
      this.createComment({ application, parent, type, code, message }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий успешно отправлен',
        });
        return this.fetchCommentList({ id: application }).then((result) => {
          this.answer = false;
          return result;
        });
      })
    },
    onEdit() {
      this.edit = true;
      this.field = this.message;
    },
    onAnswer() {
      this.answer = true;
    },
    onSave() {
      const id = this.id;
      const message = this.field;
      this.editComment({ id, message }).then(() => {
        this.edit = false;
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий обновлен',
        });
      })
    },
    onReset() {
      this.edit = false;
      this.field = this.message;
    },
  }
}
</script>

<style module lang="scss">
.root {
  border: 1px solid #dee2e6;
  padding: 10px;
  & + & {
    margin-top: 20px;
  }
  & & {
    margin-top: 10px;
    background-color: #dcffd6;
  }
}
.body {}
.badges {
  overflow: hidden;
  margin-bottom: 10px;
  text-align: right;
}
.controls {
  margin-top: 10px;
}
.answer {
  margin-top: 10px;
}
</style>
