<template>
  <div :class="$style.root">
    <VForm v-model="valid" lazy-validation ref="form" @submit.prevent="onSubmit">
      <VRow dense>
        <VRadioGroup v-model="action" :rules="rules.required" class="mt-0">
          <VRadio v-for="{value, text} in actionOptions" :label="text" :value="value" :key="value" />
        </VRadioGroup>
      </VRow>
      <VRow dense>
        <VCol cols="12" md="3">
          <div :class="$style.title">Комментарий</div>
        </VCol>
        <VCol cols="12" md="9">
          <VTextarea v-model="message" :rules="rules.required" outlined dense auto-grow rows="1" hideDetails="auto" />
        </VCol>
      </VRow>
      <VRow dense>
        <VCol cols="12" md="3">
          <div :class="$style.title">Прикрепите файлы</div>
        </VCol>
        <VCol cols="12" md="9">
          <FileFieldMultiple v-model="files" :filled="files.length > 0" :multiple="false" />
        </VCol>
      </VRow>
      <div class="mt-3">
        <VBtn color="primary" depressed class="mr-3" :disabled="disabled || !valid" @click="onSubmit">Отправить</VBtn>
        <VBtn v-if="message" color="secondary" depressed class="mr-3" @click="onReset">Отмена</VBtn>
      </div>
    </VForm>
  </div>
</template>

<script>
import { get, isArray, isEmpty } from 'lodash-es';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
import { APPLICATION_ACTIONS } from '@/store/inspect/enums';

export default {
  name: 'RevokeForm',
  components: {
    FileFieldMultiple,
  },
  props: {
    disabled: { type: Boolean },
    inspector: { type: Object },
    slavesCount: { type: Number },
  },
  data() {
    return {
      valid: true,
      files: [],
      name: '',
      message: '',
      action: get(APPLICATION_ACTIONS, 'REQUEST_TO_REVOKE_INSPECTOR.value'),
      rules: {
        required: [
          v => {
            if (isArray(v) && isEmpty(v)) return 'Обязательное поле';
            if (!v) return 'Обязательное поле';
            return true;
          }
        ]
      },
    }
  },
  computed: {
    actionOptions() {
      const inspectorCompany = get(this.inspector, 'name', 'Н/д');
      const types = [
        { value: get(APPLICATION_ACTIONS, 'REQUEST_TO_REVOKE_INSPECTOR.value'), text: `Сменить инспекционную компанию (для замены компании ${inspectorCompany} на другую)` },
      ];
      return types;
    }
  },
  methods: {
    onSubmit() {
      const message = this.message;
      if (this.$refs.form.validate()) {
        this.$emit('submit', {
          name: this.name,
          action: this.action,
          files: this.files,
          comment: message,
        });
        this.name = '';
        this.action = get(APPLICATION_ACTIONS, 'REQUEST_TO_REVOKE_INSPECTOR.value');
        this.files = [];
        this.message = '';
      }
    },
    onReset() {
      this.message = '';
      this.files = '';
      this.$emit('reset');
    }
  }
}
</script>

<style module lang="scss">
.root {

}
</style>
