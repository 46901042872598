<template>
  <SectionCard label="История изменения">
    <TimeLine :items="items" />
  </SectionCard>
</template>

<script>
import SectionCard from '@/components/inspect/SectionCard/SectionCard';
import TimeLine from '@/components/inspect/ApplicationDetail/components/TimeLine';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Timeline',
  components: {
    TimeLine,
    SectionCard,
  },
  props: {
    id: { type: String, required: true },
    state: { type: String },
  },
  computed: {
    ...mapGetters({
      pending: 'inspect/pending',
      items: 'inspect/applicationTimeline',
    }),
  },
  methods: {
    ...mapActions({
      fetchTimeline: 'inspect/fetchTimeline',
    }),
  },
  watch: {
    state: {
      immediate: true,
      handler() {
        const id = this.id;
        this.fetchTimeline({ id });
      }
    }
  }
}
</script>

<style module lang="scss">

</style>
