<template>
  <VCard tile :class="$style.root">
    <VToolbar flat dark color="primary">
      <VToolbarTitle class="px-2">
        <template v-if="isInspector">Чат с заявителем</template>
        <template v-else>Чат с инспектором</template>
      </VToolbarTitle>
      <VSpacer/>
      <VBtn icon dark @click="onClose">
        <VIcon>mdi-close</VIcon>
      </VBtn>
    </VToolbar>
    <VCardText class="pt-5">
      <div v-if="!items.length">Комментарии отсутствуют</div>
      <Comment v-for="comment in items" :class="$style.item" v-bind="comment" :key="comment.id" />
      <div class="pt-5">
        <CommentForm @submit="onSubmit" />
      </div>
    </VCardText>
  </VCard>
</template>

<script>
import { debounce, get, groupBy, map } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import Comment from '@/views/inspect/AppReportCommentsView/components/Comment';
import { USER_TYPES } from '@/store/inspect/enums';

export default {
  name: 'AppReportCommentsView',
  components: {
    Comment,
    CommentForm,
  },
  props: {
    application: { type: String },
  },
  computed: {
    ...mapGetters({
      loading: 'inspect/pending',
      list: 'inspect/chat',
      hasRole: 'user/hasRole',
    }),
    isInspector() {
      return this.hasRole([get(USER_TYPES, 'INSPECTOR.value', 'INSPECTOR')], 'inspect');
    },
    items() {
      const list = get(this.list, 'items');
      const mapper = groupBy(list, 'parent');
      return map(get(mapper, ''), (comment) => {
        return {
          ...comment,
          items: get(mapper, comment.id, []),
        }
      });
    },
  },
  methods: {
    ...mapActions({
      fetchChatList: 'inspect/fetchChatList',
      createChatComment: 'inspect/createChatComment',
      viewedChatList: 'inspect/viewedChatList',
    }),
    onUpdateState: debounce(function() {
      const id = this.application;
      if (id) this.fetchChatList({ id });
    }, 500),
    onClose() {
      const application = this.application;
      this.$router.push({ name: 'inspect/ApplicationDetailView', params: { application }});
      this.$emit('close');
    },
    onSubmit(message) {
      const application = this.application;
      this.createChatComment({ application, message }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий успешно отправлен',
        });
        return this.fetchChatList({ id: application }).then((result) => {
          return result;
        });
      })
    }
  },
  watch: {
    application: function(application) {
      if (application) this.onUpdateState();
    },
  },
  mounted() {
    this.onUpdateState();
  },
}
</script>

<style module lang="scss">
.root {}
.item {
  padding: 10px;

}
</style>
