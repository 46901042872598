<template>
  <div :class="$style.root">
    <VForm v-model="valid" lazy-validation ref="form" @submit.prevent="onSubmit">
      <VRow v-if="isInitiator && isApproved && !isFinishRequest" dense>
        <VCol cols="12" md="9">
          <VCheckbox v-model="fail" outlined dense label="Инспектор не выполнил работу"/>
        </VCol>
      </VRow>
      <VRow dense>
        <VCol cols="12" md="3">
          <div :class="$style.title">Комментарий</div>
        </VCol>
        <VCol cols="12" md="9">
          <VTextarea v-model="message" :rules="rules.required" outlined dense auto-grow rows="1" hideDetails="auto" />
        </VCol>
      </VRow>
      <VRow v-if="!hiddenRating" dense>
        <VCol cols="12" md="3">
          <div :class="$style.title">Ваша оценка</div>
        </VCol>
        <VCol cols="12" md="9">
          <VRating v-model="rating" :readonly="fail" hover />
        </VCol>
      </VRow>
      <div class="mt-3">
        <VBtn color="primary" depressed class="mr-3" :disabled="disabled || !valid" @click="onSubmit">Отправить</VBtn>
        <VBtn v-if="message" color="secondary" depressed class="mr-3" @click="onReset">Отмена</VBtn>
      </div>
    </VForm>
  </div>
</template>

<script>
import { get, isArray, isEmpty, merge } from 'lodash-es';
import { mapGetters } from 'vuex';
import { APPLICATION_STATUSES, USER_TYPES } from '@/store/inspect/enums';

export default {
  name: 'FinishForm',
  props: {
    hiddenRating: { type: Boolean },
    disabled: { type: Boolean }
  },
  data() {
    return {
      valid: true,
      name: '',
      message: '',
      fail: false,
      rating: null,
      rules: {
        required: [
          v => {
            if (isArray(v) && isEmpty(v)) return 'Обязательное поле';
            if (!v) return 'Обязательное поле';
            return true;
          }
        ]
      },
    }
  },
  methods: {
    onSubmit() {
      const message = this.message;
      const data = {
        name: this.name,
        comment: message,
        rating: this.rating,
      };
      if (this.fail) merge(data, { fail: this.fail })
      if (this.$refs.form.validate()) {
        this.$emit('submit', data);
        this.name = '';
        this.message = '';
        this.rating = null;
      }
    },
    onReset() {
     this.message = '';
     this.$emit('reset');
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      current: 'inspect/application',
    }),
    isApproved() {
      return get(APPLICATION_STATUSES, 'APPROVED.value') === this.current.state;
    },
    isFinishRequest() {
      return get(APPLICATION_STATUSES, 'FINISH_REQUEST.value') === this.current.state;
    },
    isInitiator() {
      return this.hasRole([get(USER_TYPES, 'INITIATOR.value', 'INITIATOR')], 'inspect');
    },
  },
  watch: {
    fail: function (value) {
      if (value) { this.rating = 1 }
      else { this.rating = null }
    }
  }
}
</script>

<style module lang="scss">
.root {

}
</style>
