<template>
  <VBtn fab small depressed color="white" @click="$emit('click')">
    <VIcon color="error">
      mdi-close
    </VIcon>
  </VBtn>
</template>

<script>
export default {
  name: 'RemoveBtn',
  emits: ['click'],
}
</script>

<style module lang="scss">

</style>
