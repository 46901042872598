<template>
  <VCard tile :class="$style.root">
    <VToolbar flat dark color="primary">
      <VToolbarTitle class="px-2">Комментарии</VToolbarTitle>
      <VSpacer/>
      <VBtn icon dark @click="onClose">
        <VIcon>mdi-close</VIcon>
      </VBtn>
    </VToolbar>
    <VCardText class="pt-5">
      <div v-if="!items.length">Комментарии отсутствуют</div>
      <Comment v-for="comment in items" :class="$style.item" v-bind="comment" :key="comment.id" />
      <div v-if="((isApproving && isReviewer) || (isWorkshopApproving && isWorkshopReviewer))  && !hasDraftComments" class="pt-5">
        <CommentForm @submit="onSubmit" />
      </div>
    </VCardText>
  </VCard>
</template>

<script>
import { debounce, get, filter, split, last, first, groupBy, map, find } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import Comment from '@/views/inspect/ApplicationCommentsView/components/Comment';
import { APPLICATION_STATUSES, USER_TYPES } from '@/store/inspect/enums';

export default {
  name: 'ApplicationCommentsView',
  components: {
    Comment,
    CommentForm,
  },
  props: {
    application: { type: String },
    code: { type: String },
    state: { type: String, required: true },
  },
  computed: {
    ...mapGetters({
      loading: 'inspect/pending',
      list: 'inspect/comments',
      hasRole: 'user/hasRole',
    }),
    hasDraftComments() {
      return !!find(this.items, { draft: true });
    },
    isReviewer() {
      return this.hasRole(get(USER_TYPES, 'REVIEWER.value'), 'inspect');
    },
    isWorkshopReviewer() {
      return this.hasRole(get(USER_TYPES, 'WORKSHOP_REVIEWER.value'), 'inspect');
    },
    isApproving() {
      return get(APPLICATION_STATUSES, 'APPROVING.value') === this.state;
    },
    isWorkshopApproving() {
      return get(APPLICATION_STATUSES, 'APPROVING_WORKSHOP.value') === this.state;
    },
    params() {
      const id = last(split(this.code, '-'));
      const code = first(split(this.code, '-'));
      return {
        code: code === 'product' ? id : code,
        type: code === 'product' ? 'product' : 'field',
      }
    },
    items() {
      const list = filter(get(this.list, 'items'), {
        code: this.params.code,
        type: this.params.type,
      });
      const mapper = groupBy(list, 'parent');
      return map(get(mapper, ''), (comment) => {
        return {
          ...comment,
          items: get(mapper, comment.id, []),
        }
      });
    },
  },
  methods: {
    ...mapActions({
      fetchCommentList: 'inspect/fetchCommentList',
      createComment: 'inspect/createComment',
      approveComment: 'inspect/approveComment',
    }),
    onUpdateState: debounce(function() {
      const id = this.application;
      this.fetchCommentList({ id });
    }, 500),
    onClose() {
      const application = this.application;
      this.$router.push({ name: 'inspect/ApplicationDetailView', params: { application }});
    },
    onSubmit(message) {
      const application = this.application;
      const code = this.params.code;
      const type = this.params.type;
      this.createComment({ application, type, code, message }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий успешно отправлен',
        });
        return this.fetchCommentList({ id: application }).then((result) => {
          return result;
        });
      })
    }
  },
  watch: {
    application: function(application) {
      if (application) this.onUpdateState();
    },
    code: function(code) {
      if (code) this.onUpdateState();
    },
  },
  mounted() {
    this.onUpdateState();
  },
}
</script>

<style module lang="scss">
.root {}
.item {
  padding: 10px;

}
</style>
